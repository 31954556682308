<template>
    <div>
        <Header :instanceInfo="instanceInfo" />
        <div class="container">
            <div class="col-md-12">
                <h2 class="pink">
                    Cookie Policy<br>
                    <span style="color: #888; font-size: 12px; text-transform: uppercase; max-width:30%;">Cookie Policy Last
                        updated on May 24th, 2018.</span>
                </h2>
            </div>
            <div class="col-md-12 about-block p-block">
                <p>
                    {{ instanceInfo?.name }}.com (the “Website”) uses cookies to store information on your computer. This
                    policy explains how we use cookies and may be amended, on one or more occasions, without notice. To
                    ensure that you are using the Website with full and up-to-date information of how we use cookies please
                    review this policy regularly as any amended policy will be updated on the Website. By using the Website,
                    you consent to the placement of cookies on your computer ion accordance with the terms of this policy.
                    If you do not want to accept cookies from the Website, please either disable cookies or refrain from
                    using this Website.
                </p>

                <h3>1. What are cookies?</h3>
                <p>
                    A cookie is a text-only string of information that a website transfers to the cookie file of the browser
                    on your computer’s hard disk so that the website can recognize you when you visit and remember certain
                    information about you. This can include which pages you have visited, choices you have made from menus,
                    any specific information you have entered into forms, and the time and date of your visit.
                </p>

                <h3>2. Types of cookies</h3>
                <p>
                    There are two main types of cookies:<br>
                </p>
                <ul>
                    <li>
                        <strong>Session cookies:</strong><br>
                        these are temporary cookies that expire at the end of a browser session; that is, when you leave the
                        website. Session cookies allow the website to recognize you as you navigate between pages during a
                        single browser session and allow you to use the website most efficiently. For example, session
                        cookies enable a website to remember that a user has placed items in an online shopping basket.
                    </li>
                    <li>
                        <strong>Persistent cookies:</strong><br>
                        in contrast to session cookies, persistent cookies are stored on your equipment between browsing
                        sessions until expiry or deletion. They therefore enable the website to “recognize” you on your
                        return, remember your preferences, and tailor services to you.
                    </li>
                </ul>
                In addition to session cookies and persistent cookies, there may be other cookies that are set by the
                website which you have chosen to visit, such as this Website, to provide us with information.
                <p></p>

                <h3>3. Our use of cookies</h3>
                <p>
                    We currently use, and may use in the future, the following types of cookies on this Website: <br><br>

                    We use session cookies to help us maintain security and verify your details while you use the Website
                    and as you navigate from page to page, which enables you to avoid having to re-enter your details each
                    time you enter a new page. <br><br>

                    We use persistent cookies to: <br>
                </p>
                <ul>
                    <li>Help us recognize you as a unique user when you return to the Website so that you do not have to
                        input your details multiple times as you move between pages or services</li>
                    <li>Remember how you have customized your use of the Website, such as your preferred search settings
                    </li>
                    <li>Collect and compile anonymous, aggregated information for statistical and evaluation purposes to
                        help us understand how users use the Website and help us improve the structure of the Website</li>
                </ul>
                <p></p>

                <h3>4. Refusing Cookies on this Website</h3>
                <p>
                    Most browsers are initially set to accept cookies. But you can disable cookies if you wish, generally
                    through changing your Internet software browsing settings. It may also be possible to configure your
                    browser settings to enable acceptance of specific cookies or to notify you each time a new cookie is
                    about to be stored on your computer enabling you to decide whether to accept or reject the cookie. To
                    manage your use of cookies there are various resources available to you, for example the “Help” section
                    on your browser may assist you. You can also disable or delete the stored data used by technology like
                    cookies, such as Local Shared Objects or Flash cookies, by managing your browser’s “add-on settings” or
                    visiting the website of its manufacturer. As our cookies allow you to access some of the Website’s
                    essential features, we recommend that you leave cookies enabled; otherwise, if cookies are disabled, it
                    may mean that you experience reduced functionality or will be prevented from using this Website
                    altogether. If you wish to turn off cookies from specific parties, please visit
                    www.youronlinechoices.eu. <br><br>

                    <strong>Internet Explorer:</strong>
                </p>
                <ul>
                    <li>Open your Internet Explorer browser.</li>
                    <li>Click the "Tools" button, and then click "Internet Options".</li>
                    <li>Click the "Privacy" tab, and then, under "Settings" move the slider to the top to block all cookies,
                        and then click "OK".</li>
                </ul>
                <strong>Edge:</strong>
                <ul>
                    <li>Open your Edge browser.</li>
                    <li>Click the "…" button, and then click "Settings".</li>
                    <li>At the bottom of the page, click the "View advanced settings" button, and then, at the bottom of the
                        page under "Cookies," select "Block all cookies" or "Block only third party cookies."</li>
                </ul>
                <strong>Google Chrome:</strong>
                <ul>
                    <li>Open your Google Chrome browser.</li>
                    <li>Click on the tool icon.</li>
                    <li>Select Settings.</li>
                    <li>At the bottom of the page, click "Show advanced settings".</li>
                    <li>In the "Privacy" section, click "Content settings".</li>
                    <li>To disable cookies, select "Block sites from setting any data".</li>
                </ul>
                <strong>Mozilla Firefox:</strong>
                <ul>
                    <li>Open your Mozilla Firefox browser.</li>
                    <li>Click the menu button and choose "Options."</li>
                    <li>Select the "Privacy" panel.</li>
                    <li>Set "Firefox will:" to "Use custom settings for history."</li>
                    <li>Uncheck "Accept cookies from sites."</li>
                    <li>Close the "about:preferences" page. Any changes you’ve made will automatically be saved.</li>
                </ul>
                <strong>Safari:</strong>
                <ul>
                    <li>Open your Safari browser.</li>
                    <li>Click on "Preferences" in the toolbar and then click on "Privacy" </li>
                    <li>In the “Block cookies” section, you can specify if and when Safari should accept cookies from
                        websites.</li>
                    <li>If you want to see which websites already store cookies on your computer, click Details.</li>
                </ul>
                <p></p>
                <p>
                    E-mail: {{ instanceInfo?.supportEmail }} if you want more
                    information about our Cookie Policy.
                </p>


                <h3>5. Cookies</h3>

                <h4>Please see the list below, of all the "necessary cookies" this website sets. This includes details of
                    the cookie name, what it does, why we use it, and when it expires.</h4>
                <table class="table">
                    <tbody>
                        <tr>
                            <td><strong>Partner</strong></td>
                            <td><strong>Cookie</strong></td>
                            <td><strong>Description</strong></td>
                        </tr>
                        <tr>
                            <td>Cloudflare</td>
                            <td>_cfd_uid</td>
                            <td>The __cfduid cookie is used to identify individual clients behind a shared IP address and
                                apply security settings on a per-client basis.</td>
                        </tr>
                        <tr>
                            <td>{{ instanceInfo?.name }}</td>
                            <td>_cb_enabled</td>
                            <td>This cookie is associated with a free script which shows a cookie alert notice on a website.
                                It is used to record when the user has dismissed the notice to prevent it re-appearing on
                                return visits.</td>
                        </tr>
                        <tr>
                            <td>{{ instanceInfo?.name }}</td>
                            <td>Session</td>
                            <td>This cookie preserves users states across page requests by storing session related
                                information.</td>
                        </tr>

                    </tbody>
                </table>

                <h4>Analytical cookies</h4>
                <table class="table">
                    <tbody>
                        <tr>
                            <td><strong>Partner</strong></td>
                            <td><strong>Cookie</strong></td>
                            <td><strong>Description</strong></td>
                        </tr>
                        <tr>
                            <td>{{ instanceInfo?.name }}</td>
                            <td>_pk_id</td>
                            <td>This cookie determines if you are a new or&nbsp;returning&nbsp;visitor.&nbsp;This cookie is
                                valid for 1 year.</td>
                        </tr>
                        <tr>
                            <td>{{ instanceInfo?.name }}</td>
                            <td>_pk_ses</td>
                            <td>This cookie determines which pages you have visited on {{ instanceInfo?.name }}. The cookie
                                is only
                                valid during that specific visit.</td>
                        </tr>
                        <tr>
                            <td>{{ instanceInfo?.name }}</td>
                            <td>_pk_ref</td>
                            <td>This cookie tracks from which website you are visiting the {{ instanceInfo?.name }} website.
                                It is
                                valid for 6 months.</td>
                        </tr>
                        <tr>
                            <td>{{ instanceInfo?.name }}</td>
                            <td>transaction_id</td>
                            <td>This website uses affiliate tracking cookies to allow us to see if our customers have come
                                to the Website through one of our partners so that we can credit them appropriately.</td>
                        </tr>
                        <tr>
                            <td>{{ instanceInfo?.name }}</td>
                            <td>sub_id</td>
                            <td>This website uses affiliate tracking cookies to allow us to see if our customers have come
                                to the Website through one of our partners so that we can credit them appropriately.</td>
                        </tr>
                        <tr>
                            <td>{{ instanceInfo?.name }}</td>
                            <td>req_id</td>
                            <td>This website uses affiliate tracking cookies to allow us to see if our customers have come
                                to the Website through one of our partners so that we can credit them appropriately.</td>
                        </tr>
                        <tr>
                            <td>{{ instanceInfo?.name }}</td>
                            <td>aff_id</td>
                            <td>This website uses affiliate tracking cookies to allow us to see if our customers have come
                                to the Website through one of our partners so that we can credit them appropriately.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <Footer :instance-info="instanceInfo" :company-name="companyName" v-bind="$attrs"/>
    </div>
</template>

<script>
import Header from '@/app/components/Header';
import Footer from '@/app/components/Footer';

export default {
    name: 'Cookies',
    components: { Header, Footer },
    props: {
        instanceInfo: {
            type: Object,
            required: false,
        },
        companyName: {
          type: String,
          required: false,
        },
    },
};
</script>
